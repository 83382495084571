#meet-driver{
  .info-container {
    max-width: 580px;
    min-height: 466px;
    width: 100%;
    background: gray;

    @media screen and (max-width: 991px) {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }

  .info-container1 {
    background: url('../../../Assets/Homepage/PNG/meet-pattern-lines.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:after {
      content: '';
      position: absolute;
      top: -45px;
      left: -120px;
      width: 350px;
      height: calc(100% + 100px);
      background: url('../../../Assets/Homepage/PNG/meet-left-img.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .info-container2 {
    background: url('../../../Assets/Homepage/PNG/meet-pattern-lines2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:after {
      content: '';
      position: absolute;
      top: -45px;
      right: -120px;
      width: 350px;
      height: calc(100% + 100px);
      background: url('../../../Assets/Homepage/PNG/meet-right-img.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 1250px) {
    .info-container1 {
      &:after {
        left: -50px;
      }
    }
    .info-container2 {
      &:after {
        right: -50px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .info-container1 {
      &:after {
        width: 250px;
        top: auto;
        left: 0;
        bottom: -64px;
      }
    }
    .info-container2 {
      &:after {
        top: auto;
        width: 250px;
        bottom: -50px;
        right: 0;
      }
    }
  }

  .p-container {
    width: 80px;
    aspect-ratio: 1 / 1;
    background: #123318;
  }
}
