.calendar {
    .slider-right {
        height: 350px;

        .overflow-slider {
            left: 0;
            width: 100%;
            align-items: center !important;
        }
    }
    .calendar-item {
        min-width: 300px;
        position: relative;
        padding: 0 30px;

        .right-border {
            width: 1px;
            height: 230px;
            background: #414042;
            position: absolute;
            top: 50%;
            right: -15px;
            transform: translateY(-50%);
        }

        &:last-child {
            .right-border {
                display: none;
            }
        }
    }
}