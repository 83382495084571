@import '../../Style/colors';

nav {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;

  .links-container {
    color: $colorWhite;
    padding: 1rem 0.5rem;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
  }

  .main-logo {
    // @media screen and (max-width: 991px) {
    //   width: 64px;
    //   height: auto;
    // }
  }

  ul {
    list-style: none;
  }

  .buy-container {
    width: 92px; //same as the left icon - for the flexbox to center properly
  }

  .buy-button {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 0;
    border-radius: 16px;
    padding: 1rem;
    width: 58px;
    height: 58px;
  }

  /////////////////

  #dropdown-button-active {
    color: $navbar_main_color;
  }

  .active-sub-route {
    color: $navbar_main_color;
  }

  .active-route {
    color: $navbar_main_color !important;
  }

  .dropdown-menu-dark {
    background: rgba(255, 255, 255, 0.1);
    // margin-top: 15px;
    transform: translate3d(0px, 54px, 0px) !important;
    backdrop-filter: blur(10px);

    .dropdown-item {
      color: #ffffff;
      transition: 0.3s;

      &:focus-visible {
        outline: none;
      }
      &:active {
        background-color: $navbar_main_color;
      }

      &:hover {
        color: $navbar_main_color;
      }
    }
  }

  .show {
    #dropdown-button,
    #dropdown-button-active {
      &::after {
        transform: translateY(3px) rotate(180deg);
      }
    }
  }

  #dropdown-button,
  #dropdown-button-active {
    padding-block: 0;
    background-color: transparent;
    border: none;
    &::after {
      border: none;
      width: 12px;
      height: 7px;
      background-image: url('../../Assets/Navbar/SVG/menu-arrow-down.svg');
      background-position: bottom;
      background-repeat: no-repeat;
      transition: 0.2s;
      transform: translateY(3px);
    }

    &:hover {
      color: $navbar_main_color;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-route {
    color: #ffffff;
    cursor: pointer;
    padding: 0 0.75rem;
    transition: 0.3s;

    &:hover {
      color: $navbar_main_color;
    }
  }

  //mobile

  .button-menu-mobile {
    width: 38px;
    height: 38px;
    position: relative;
    cursor: pointer;

    div {
      border-radius: 2px;
    }

    .line1 {
      position: absolute;
      top: 5px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #ffffff;
      transition: 0.3s;
    }
    .line2 {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #ffffff;
      transform: translateY(-50%);
      transition: 0.3s;
    }
    .line3 {
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #ffffff;
      transition: 0.3s;
    }
  }

  .button-menu-mobile-expanded {
    .line2 {
      opacity: 0;
    }

    .line1 {
      transform: translate(0, 12px) rotate(45deg);
    }

    .line3 {
      transform: translate(0, -12px) rotate(-45deg);
    }
  }

  //mobile menu

  .routes-container-mobile {
    position: fixed;
    top: 5rem;
    right: 0;
    width: 100%;
    // min-width: 200px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 99;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 0rem;
    //   right: 0;
    //   width: 100%;
    //   height: 100%;
    //   filter: blur(20px);
    //   z-index: -1;
    // }
  }

  .navbar-not-expanded {
    max-height: 0;
    padding-inline: 1.5rem;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .navbar-expanded {
    padding-inline: 1.5rem;
    overflow: hidden;
    max-height: 100vh;
    transition: max-height 0.3s ease-in-out;
    background-color: #000000c8;

    @media screen and (max-height: 450px) {
      height: calc(100vh - 54px) !important;
      overflow-y: scroll !important;
    }
  }

  .navbar-route-mobile,
  .navbar-route,
  #dropdown-button {
    text-shadow: 0 0 5px #000000, 0 0 5px #000000;
  }

  // accordion css
  .accordion {
    width: 100%;
    padding-block: 1.5rem;

    .accordion-item {
      background-color: transparent;
      border: 0;
    }
    .accordion-body {
      padding-block: 0;
    }

    .navbar-route-mobile {
      color: $colorWhite;
    }

    .accordion-button {
      color: #ffffff;
      box-shadow: none;
      background-color: transparent;
      border: 0;
      padding: 0.5rem 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:after {
        display: none;
      }

      &:focus,
      &:focus-visible {
        box-shadow: none;
        outline: none;
      }

      svg {
        fill: #ffffff;
        transition: 0.2s;
        transform: rotate(45deg);
      }
    }

    .collapsed {
      svg {
        transform: rotate(0);
      }
    }
  }
}
