@import './buttons';
@import './colors';
@import './container';
@import './fonts';
@import './fontSizes';
@import './zIndex';
@import './lineHeight';
@import './cursors';
@import './borderRadius';
@import './spacings';
@import './helpers';

//general styling
html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  background: $colorBlack;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
