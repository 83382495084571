#our-partners {
  .blue-col {
    // height: 580px;

    @media screen and (max-width: 768px) {
        height: auto;
      }

    .content {
      height: 100%;
      background-size: cover;
      border-radius: 15px;
      background-repeat: no-repeat;
      background-image: url('../../../Assets/Homepage/PNG/blue-wave.png');
    }
  }
  .green-col {
    // height: 580px;

    @media screen and (max-width: 768px) {
      height: auto;
    }

    .content {

      border-radius: 15px;
      // background-color: #32cd32;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../../../Assets/Homepage/PNG/green-wave.png');

      > div {
        flex: 1 1 30%;
        @media screen and (max-width: 991px) {
          text-align: center;
          flex: 1 1 50%
        }
        @media screen and (max-width: 768px) {
          text-align: center;
          flex: 1 1 100%
        }
      }
    
    .m-auto{
        min-height: 65px;
        @media screen and (max-width: 768px) {
            min-height: auto;
          }
    }
    }
  }
}
