@font-face {
  font-family: 'Rift';
  src: url('../Assets/Fonts/Rift-Regular.otf');
}
@font-face {
  font-family: 'Titillium';
  src: url('../Assets/Fonts/TitilliumWeb-Regular.ttf');
}

.font-ti-r {
  font-family: 'Titillium';
  font-weight: normal;
}

.font-ti-l {
  font-family: 'Titillium';
  font-weight: lighter;
}
.font-ti-sb {
  font-family: 'Titillium';
  font-weight: bold;
}
.font-ti-b {
  font-family: 'Titillium';
  font-weight: bolder;
}

.font-ri-r {
  font-family: 'Rift';
  font-weight: normal;
}

.font-ri-l {
  font-family: 'Rift';
  font-weight: lighter;
}

.font-ri-sb {
  font-family: 'Rift';
  font-weight: bold;
}

.font-ri-b {
  font-family: 'Rift';
  font-weight: bolder;
}
