@import '../../../../../../Style/colors';

#podcast-news {
  min-width: 342px;
  background: $colorWhite;

  @media screen and (max-width: 991px) {
    min-width: 380px;
  }

  @media screen and (max-width: 767px) {
    min-width: 300px;
  }
  .play-button{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
  }
  .overlay-podcast{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    // gradient background from bottom to top 
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
  }

  .main-content-container {
    &:after {
      content: '';
      position: absolute;
      top: -16px;
      left: -16px;
      width: 32px;
      height: 32px;
      background: $colorBlack;
      border-radius: 50%;
    }
    &:before {
      content: '';
      position: absolute;
      top: -16px;
      right: -16px;
      width: 32px;
      height: 32px;
      background: $colorBlack;
      border-radius: 50%;
    }
  }

  .tag-container {
    background: $colorDarkBlue;
    border-radius: 24px;
    padding: 0.25rem 1rem;
  }
}
