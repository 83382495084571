@mixin radius($value) {
  border-radius: $value;
}

.radius1 {
  @include radius(1px);
}
.radius2 {
  @include radius(2px);
}
.radius3 {
  @include radius(3px);
}
.radius4 {
  @include radius(4px);
}
.radius5 {
  @include radius(5px);
}
.radius6 {
  @include radius(6px);
}
.radius7 {
  @include radius(7px);
}
.radius8 {
  @include radius(8px);
}
.radius9 {
  @include radius(9px);
}
.radius10 {
  @include radius(10px);
}
.radius11 {
  @include radius(11px);
}
.radius12 {
  @include radius(12px);
}
.radius13 {
  @include radius(13px);
}
.radius14 {
  @include radius(14px);
}
.radius15 {
  @include radius(15px);
}
.radius16 {
  @include radius(16px);
}
.radius17 {
  @include radius(17px);
}
.radius18 {
  @include radius(18px);
}
.radius19 {
  @include radius(19px);
}
.radius20 {
  @include radius(20px);
}
.radius21 {
  @include radius(21px);
}
.radius22 {
  @include radius(22px);
}
.radius23 {
  @include radius(23px);
}
.radius24 {
  @include radius(24px);
}
.radius25 {
  @include radius(25px);
}
.radius26 {
  @include radius(26px);
}
.radius27 {
  @include radius(27px);
}
.radius28 {
  @include radius(28px);
}
.radius50p {
  @include radius(50%);
}
