.cursor-pointer {
  cursor: pointer;
}

.cursor-normal {
  cursor: default;
}

.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-none {
  cursor: none;
}
