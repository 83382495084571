.lh-1 {
  line-height: 1 !important;
}

.lh-11 {
  line-height: 1.1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-13 {
  line-height: 1.3;
}

.lh-14 {
  line-height: 1.4;
}

.lh-15 {
  line-height: 1.5;
}

.lh-2 {
  line-height: 2;
}
