@import '../../Style/colors';

.track-banner {
  width: 100%;
  height: 120px;
  margin-top: -120px;
  z-index: 4;
  position: relative;
  background: $colorDarkBlue;
  overflow: hidden;

  @media screen and (max-width: 1250px) {
    height: 240px;
    margin-top: -240px;
  }

  .overflow-container {
    position: absolute;
    top: 0;
    left: 24px;
    height: 120px;
    width: calc(100vw - max(calc(calc(100vw - 1420px) / 2), 24px));

    @media screen and (max-width: 1250px) {
      flex-direction: column !important;
      min-height: 240px;
      z-index: 4;
      left: 0;
      width: 100vw;
    }

    .left-container {
      background: $colorDarkBlue;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 1250px) {
        padding-left: 1.5rem;
      }

      @media screen and (max-width: 991px) {
        padding-left: 0.75rem;
      }
    }

    .right-container {
      background: $colorDarkGreen;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 1250px) {
        padding-left: 1.5rem;
        min-height: 120px;

        .up-next {
          padding-left: 0 !important;
        }
      }

      @media screen and (max-width: 991px) {
        padding-left: 0.75rem;
      }
    }
  }

  .date-container {
    background: rgba(255, 255, 255, 0.05);
  }
  .position-container {
    background: rgba(255, 255, 255, 0.05);
    width: 100px;
    height: auto;
    aspect-ratio: 1 / 1;

    @media screen and (max-width: 1250px) {
      width: 70px;
    }
  }

  .button-hero {
    @media screen and (max-width: 991px) {
      width: 140px !important;
    }
  }

  @media screen and (max-width: 510px) {
    .track-img,
    .positions-container {
      display: none !important;
    }

    .left-container,
    .right-container {
      justify-content: flex-start !important;
      min-height: 95px !important;
    }

    .button-hero {
      width: 200px !important;
    }

    height: 190px;
    margin-top: -190px;
    .overflow-container,
    .track-banner {
      min-height: 190px;
    }
  }
}
