#about-racing {
  height: 800px;
  .container-main {
    background: url('../../../Assets/Homepage/PNG/b.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  @media screen and (max-width: 767px) {
    height: 400px;
  }
}
