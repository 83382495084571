.section-team {
    .slider-right {
        height: 600px;
        overflow: hidden;

        @media screen and (max-width: 500px) {
            height: 415px;
        }

        .overflow-slider {
            left: 0;
            width: 100%;
        }
    }

    .team-card {
        min-width: 464px;

        @media screen and (max-width: 500px) {
            min-width: 320px;
            .member-image {
                img {
                    max-width: 320px;
                }
            }

            .hide-description {
                bottom: auto;
                top: 26px;
            }
        }
    }

    .member-card {
        border-radius: 8px;

        .member-info {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 40px;
            left: 0;
        }
        .show-description, .hide-description {
            position: absolute;
            right: 26px;
            bottom: 26px;
            &:hover {
                opacity: 0.7;
            }
        }
        .hide-description {
            right: 32px;
            height: 32px;
        }
        .member-description {
            position: absolute;
            top: 0;
            overflow: auto;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            z-index: 2;
            background: #1a1a1a url(./assets/about-us-description-pattern.png) no-repeat center center;
            transition: all .5s cubic-bezier(0.165, 0.84, 0.44, 1);
            transform: translateX(100%);
    
            &.active {
                transform: none;
            }

            h3 {
                margin-top: 40px;
            }
        }
    }
    .driver-left, .driver-right {
        width: 580px;
        height: 460px;
        border-radius: 8px;
        position: relative;
        background: #1a1a1a url(./assets/driver_pattern.png) no-repeat center center;
    }
    .driver-left {
        .driver-image-tall {
            width: 511px;
            height: 685px;
            position: absolute;
            bottom: 0;
            left: -120px;
            border-radius: 8px;
            background:  url(./assets/driver1_tall.png) no-repeat center center;
        }
    }
    .driver-right {
        .driver-image-tall {
            width: 511px;
            height: 685px;
            position: absolute;
            bottom: 0;
            right: -150px;
            border-radius: 8px;
            background: url(./assets/driver2_tall.png) no-repeat center center;
        }
    }
}