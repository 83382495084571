.container-main {
  margin-inline: auto;
  width: 100%;
  max-width: 1470px;
  padding: 0 1.5rem;
  //   height: 100%;

  @media screen and (max-width: 991px) {
    padding: 0 0.75rem;
  }
}

.container-navbar {
  margin-inline: auto;
  max-width: 1800px;
  padding: 0 1.5rem;

  @media screen and (max-width: 991px) {
    padding: 0 0.75rem;
  }
}
