.hero-partners {
  height: 900px;
  background-image: url('./assets//bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media screen and (max-width: 991px) {
    height: 600px;
  }
}
#partners {
  .hero-partners {
    height: 900px;
    background-image: url('./assets//bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 991px) {
      height: 600px;
    }
  }
  @media screen and (max-width: 767px) {
    .w-md-auto {
      width: fit-content;
    }
  }
  .title-partners {
    color: #0b91d8;
  }
  .w-fit {
    width: fit-content;
  }
  .orange-color {
    color: #ff9300;
  }
  .green-color {
    color: #007e4b;
  }
  .red-color {
    color: #ff0000;
  }
  // .green-box-shadow{
  //   box-shadow:   0 0 41px rgb(89 255 43 / 70%)
  // }
  // .orange-box-shadow{

  //     box-shadow: 0px 0px 41px 0px #FF9300;
  // }
  // .red-box-shadow{
  //     box-shadow: 0px 0px 41px 0px #FF0000;
  // }
  // .blue-box-shadow{
  //     box-shadow: 0px 0px 41px 0px #0B91D8;
  // }
  .w-fit-content {
    width: fit-content;
  }
  a {
    text-decoration: none;
  }
}
