@import '../../../../Style/colors';

#news-list{
  .img-blog{
    object-fit: cover;
  }
    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -30px; /* gutter size offset */
        width: auto;
      }
      .my-masonry-grid_column {
        padding-left: 30px; /* gutter size */
        background-clip: padding-box;
      }
      img{
        width: 100%;
        object-fit: cover;

      }
      .border-12 {
        border-radius: 12px !important
      }
      /* Style your items */
      .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
        background: grey;
        margin-bottom: 30px;
      }
      
    .h-159 img{
        height: 159px;
    }
    .diff-size-img .one{
        height: 200px;
    }
    .h-320 img{
        height: 320px;

    }
    .h-342 img{
        height: 342px;
    }
    .h-300 img{
        height: 300px;
    }
    .h-420 img{
        height: 420px;
    }
    @media screen and (max-width: 600px) {
      .h-159 img{
        height: 320px;
    }

    .h-320 img{
        height: 320px;

    }
    .h-342 img{
        height: 342px;
    }
    .h-300 img{
        height: 300px;
    }
    .h-420 img{
      height: 320px;
    }
      // min-width: 300px;
    }
    #news-element {
        max-width: 342px;
        background: $colorWhite;
      
        @media screen and (max-width: 991px) {
       
        }
      
     
      
        .main-content-container {
         
        }
      
        .tag-container {
          background: $colorDarkBlue;
          border-radius: 24px;
          padding: 0.25rem 1rem;
        }
      }
}