@import '../../Style/colors';
#blog-page {
  .w-fit-content {
    width: fit-content;
  }
  .title-component {
    max-width: 962px;
  }
  .quote-blog {
    text-align: center;
    padding: 6rem 2rem;
    background-image: url('./assets/pattern-lines.png');
    @media screen and (max-width: 991px) {
      padding: 2rem 2rem;
    }
    h3 {
      color: $colorMainGreen;
    }
    blockquote {
      margin: auto;
      max-width: 756px;
    }
  }
.green-color-link{
  color: #1def9b;
}
  .hero-blog {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 740px;
    display: flex;
    position: relative;
    // overlay gradient
    .container-main {
      position: relative;
      z-index: 1;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
    @media screen and (max-width: 991px) {
      height: 500px;
    }
  }
  @media screen and (max-width: 991px) {
    .font-size-24 {
      font-size: 14px;
    }
  }
}
