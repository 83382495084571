.current-race {
    .race-image {
        &.circuit {
            background: #121413;
            border-radius: 8px;
            height: 334px;
            display: flex;
            align-items: center;
            justify-content: center;

        }
        img{
            max-height: 100%;
        }
    }
    .qualifier-position {
        max-width: fit-content;
    }
    .feature {
       > div {
        background: #1C1C1C;
       }
    }
}