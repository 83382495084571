@import '../../Style/colors';

#hero {
  max-width: 2500px;
  min-height: 1080px;
  height: 100%;
  margin-inline: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 3;

  @media screen and (max-width: 1200px) {
    min-height: 900px;
  }

  @media screen and (max-width: 991px) {
    min-height: 800px;
  }
  @media screen and (max-width: 767px) {
    min-height: 700px;
  }

  @media screen and (min-width: 2400px) {
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      height: 100%;
      width: 100px;
      background: linear-gradient(
        to right,
        $colorBlack,
        rgba(0, 0, 0, 0.5),
        transparent
      );
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      right: -1px;
      height: 100%;
      width: 100px;
      background: linear-gradient(
        to left,
        $colorBlack,
        rgba(0, 0, 0, 0.5),
        transparent
      );
    }
  }

  .element-title {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .mb-banner {
    margin-bottom: 140px;

    @media screen and (max-width: 1250px) {
      margin-bottom: 260px;
    }
  }

  .slick-slider {
    width: 100%;
  }

  .slick-track,
  .slick-slider,
  .slick-list,
  .slick-slide > div {
    height: 100%;
  }

  .img-slider-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    .overlay-background{
      //overlay 
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: url('./assets/mask.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .bg-img {
      object-fit: cover;
      min-width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .progress-div {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background: $colorDarkGray;
    z-index: 4;
    overflow: hidden;

    position: absolute;
    top: -10px;
    left: 0;

    @media screen and (max-width: 767px) {
      height: 8px;
    }
  }

  .anim-title {
    animation: fade-out-in 0.6s ease-in-out;
  }

  @keyframes fade-out-in {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    49% {
      transform: translateY(20px);
      opacity: 0;
    }
    50% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .progress-container {
    width: 180px;
  }

  .future-progress {
    background: $colorDarkGray;
  }
  .past-progress {
    background: $colorMainGreen;
  }
  .current-progress {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      height: 100%;
      background: $colorMainGreen;
      animation: progress 10s linear forwards;

      // @media screen and (max-width: 767px) {
      //   height: 0;
      //   width: 4px;
      //   animation: progress2 10s linear forwards;
      // }
    }
  }

  @keyframes progress {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  @keyframes progress2 {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }
}
