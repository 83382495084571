#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 543px;

  @media screen and (max-width: 991px) {
    height: auto;
    padding: 50px 0;
  }
  background-color: #0f0f0f;
  .mw-700 {
    max-width: 700px;
    width: 100%;
  }
  hr {
    color: aliceblue;
  }
  .input-subscribe {
    background-color: #2d2d2d;
    border: none;
    border-radius: 8px;
    height: 56px;
    width: 100%;
    color: white;
    padding: 0 20px;
    max-width: 463px;
    :focus {
      outline: none;
    }
  }
  .input-subscribe:focus-visible {
    outline: none;
  }
  .socials{ 
    a{
      color: white;
      // margin: 0 10px;
      font-size: 20px;
      text-decoration: none;

      span:last-of-type {
        @media screen and (max-width: 768px) {
          margin-left: 20px;
        }
      }
    }
  }
}
