.image-gallery {
    img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
    }
    .main-gallery {
        overflow: hidden;
        height: 860px;

        @media screen and (max-width: 991px) {
            height: 300px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .secondary-gallery {
        .slider-right {
            padding: 0;
            height: 270px;

            @media screen and (max-width: 991px) {
                height: 120px;
            }
            
            .overflow-slider {
                left: auto;
                width: 100%;
            }
        }
        .secondary-gallery-item {
            height: 270px;
            width: 230px;
            min-width: 230px;
            border-radius: 10px;
            opacity: .6;

            @media screen and (max-width: 991px) {
                width: 120px;
                min-width: 120px;
                height: 120px;
            }

            &.active {
                opacity: 1;
                border: 2px solid #fff;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}