$colorWhite: #ffffff;
$colorBlack: #000000;

$bgGray: #141414;
$colorDarkGray: #414042;
$colorGray: #848484;
$colorLightGray: #cbcbcb;

$navbar_main_color: #0bd885;
$navbar_dark_color: #006039;

$colorMainGreen: #0bd885;
$colorDarkGreen: #006039;

$colorDarkBlue: #031b3f;

.white-color {
  color: $colorWhite;
}

.black-color {
  color: $colorBlack;
}

.gray-color {
  color: $colorGray;
}

.light-gray-color {
  color: $colorLightGray;
}

.main-green-color {
  color: $colorMainGreen;
}
