@import '../../../../Style/colors';

#slide-news {
  .img-blog{
    object-fit: cover;
  }
  min-width: 450px;
  background: $colorWhite;

  @media screen and (max-width: 991px) {
    min-width: 380px;
  }

  @media screen and (max-width: 767px) {
    min-width: 300px;
  }

  .main-content-container {
    &:after {
      content: '';
      position: absolute;
      top: -16px;
      left: -16px;
      width: 32px;
      height: 32px;
      background: $colorBlack;
      border-radius: 50%;
    }
    &:before {
      content: '';
      position: absolute;
      top: -16px;
      right: -16px;
      width: 32px;
      height: 32px;
      background: $colorBlack;
      border-radius: 50%;
    }
  }

  .tag-container {
    background: $colorDarkBlue;
    border-radius: 24px;
    padding: 0.25rem 1rem;
  }
}
